import React, { ComponentType, ComponentProps, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';


const hoverOn = (e: any) => { e.target.classList.add('nav-button-hover'); };
const hoverOff = (e: any) => 
{ 
  e.target.classList.remove('nav-button-hover');
};

type Props = {
    to: string;
    align: string;
    children: ReactNode;
    onClick?: React.MouseEventHandler;
    className?: string;
    isCollapsed?: boolean;
}

function CoolNavLink({ to, align, children, onClick, className, isCollapsed }: Props) {
    const rest_class =  `select-none transition-[width] duration-500 nav-button text-${align} ${className ? className : ''} ${isCollapsed ? 'w-10' : 'w-full'}`; 
    const isActive = ({ isActive }: { isActive: boolean }) => (isActive ? 'nav-button-active ' + rest_class : 'nav-button ' + rest_class);

    return (
        <NavLink to={to} className={isActive}  onMouseEnter={(e) => hoverOn(e)} onMouseLeave={(e) => hoverOff(e)} onClick={onClick}>{isCollapsed ? ((children as string).split(' ').map((word: string, index: number) => (<span key={index}>{word.charAt(0)}</span>))) : children}</NavLink>
    )
}

export default CoolNavLink;

