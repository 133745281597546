import { useEffect, useState } from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import type { ChartData, ChartDataset } from 'chart.js';
import { Chart as chartjs, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement } from 'chart.js';
import annotationPlugin from "chartjs-plugin-annotation";
import CoolNavLink from '../components/CoolNavLink';
import moment from 'moment';

import { db_Month, db_Availability, viewModes, db_Site } from '../models';
import { relativeTimeRounding } from 'moment';
import { fetcher } from '../request';
import CustomDatePicker from '../components/CustomDatePicker';
import { eventToState, getStartDateDB } from '../helpers';
import { ArrowSmLeftIcon, ArrowSmRightIcon } from '@heroicons/react/outline';

export const CHART_COLORS = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
};

export function valueOrDefault<T>(value: T | undefined, defaultValue: T) 
{
    return typeof value === 'undefined' ? defaultValue : value;
}

function SiteOverview() 
{
    const [chartData, setChartData] = useState<ChartData<"bar", number[], string[]>>({ labels: [ ], datasets: [ ] });
    const [threshold, setThreshold] = useState<number>(0);
    const [pathChanged, setPathChanged] = useState<boolean>(false);
    const [viewMode, setViewMode] = useState<viewModes>(viewModes.Overview_Finance_Sites);
    let initialDate = moment().startOf('month');
    const [startDate, setStartDate] = useState<moment.Moment>(initialDate);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalBudget, setTotalBudget] = useState<number>(0);
    const [totalBudgetExterior, setTotalBudgetExterior] = useState<number>(0);
    const [totalBudgetInterior, setTotalBudgetInterior] = useState<number>(0);

    //key hooks
    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): any => {
            const key = e.key;

            if (key === 'ArrowLeft') {
                setStartDate(moment(startDate).subtract(1, 'month'));
            }
            else if (key === 'ArrowRight') {
                setStartDate(moment(startDate).add(1, 'month'));
            } 
            else {
                return;
            }
            e.preventDefault();
        };

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        }
    }, [startDate]);

    chartjs.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement, annotationPlugin );
    
    let seed = 3;
    function random(min: number, max: number) 
    {
        // https://stackoverflow.com/a/424445/12802214
        let x = Math.sin(seed += 2) * 10000;
        let float = x - Math.floor(x);
        return Math.floor(float * (max - min) + min);
    }
    
    const config = useEffect (() => 
    {
        const fetchData = async (localViewMode: viewModes) => 
        {
            setLoading(true);
            const n_Months = 12;
            let date = new Date(startDate.valueOf());

            let labels: string[] = [];
            let datasets: ChartDataset<"bar", number[]>[] = [];

            //defaults to 0 so all values will be gone if it was missing
            let finance_ratio = 0;
            if(localViewMode == viewModes.Overview_Finance_Sites || localViewMode == viewModes.Overview_Finance_All)
            {
                //readout finance ratio setting
                let settings_res = await fetcher(`${process.env.REACT_APP_API_URL}/Globals/Finanzen_AbzugProzent`);
                let percent_json = await settings_res.json();
                const sub_percent: number = parseFloat(percent_json.Value);
                finance_ratio = 1 - sub_percent / 100;
            }
            //readout working hours setting
            let settings_res = await fetcher(`${process.env.REACT_APP_API_URL}/Globals/WorkingHours`);
            let percent_json = await settings_res.json();
            const workingHours: number = parseFloat(percent_json.Value);

            for(let i = 0; i < n_Months; i++) 
            {
                labels.push(`${date.getFullYear()}-${date.getMonth()+1}`);
                let date_str = `${date.getFullYear()}-` + `${date.getMonth()+1}`.padStart(2, '0') + `-01`;
                let endDate = new Date(date.valueOf());
                endDate.setMonth(endDate.getMonth() + 1);
                let endDate_str = `${endDate.getFullYear()}-` + `${endDate.getMonth()+1}`.padStart(2, '0') + `-01`;

                if(localViewMode == viewModes.Overview_Finance_All)
                {
                    if(datasets.length == 0)
                    {
                        let load_exterior_row = {
                            backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`,
                            label: 'Auftragsvolumen aussen',
                            data: new Array(n_Months).fill(0),
                            stack: 'Stack 0',
                        };
                        let load_interior_row = {
                            backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`,
                            label: 'Auftragsvolumen innen',
                            data: new Array(n_Months).fill(0),
                            stack: 'Stack 0',
                        };
                        let target_row = {
                            backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`,
                            label: 'Zielumsatz',
                            data: new Array(n_Months).fill(0),
                            stack: 'Stack 1',
                        };
                        let cost_row = {
                            backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`,
                            label: 'Mitarbeiterkosten',
                            data: new Array(n_Months).fill(0),
                            stack: 'Stack 2',
                        }; 

                        datasets.push(load_exterior_row);
                        datasets.push(load_interior_row);
                        datasets.push(target_row);
                        datasets.push(cost_row);
                    }

                    const res = await fetcher(`${process.env.REACT_APP_API_URL}/Site?Start=${date_str}&End=${endDate_str}`);
                    const res_json = await res.json();
                    res_json.forEach((row: db_Site, index: number) => 
                    {
                        if(row.ExteriorWork === 1)
                        {
                            //Auftragsvolumen aussen
                            datasets[0].data[i] += Math.round(row.Budget * finance_ratio * (row.days_ratio != null ? row.days_ratio : 1));
                        }
                        else
                        {
                            //Auftragsvolumen innen
                            datasets[1].data[i] += Math.round(row.Budget * finance_ratio * (row.days_ratio != null ? row.days_ratio : 1));
                        }
                    });

                    const res2 = await fetcher(`${process.env.REACT_APP_API_URL}/Availability?Start=${date_str}T00:00:00Z&End=${endDate_str}T00:00:00Z`);
                    const res2_json = await res2.json();
                    res2_json.forEach((row: db_Availability, index: number) => 
                    {
                        //Zielumsatz (verfügbare Stunden * Zielumsatz pro Stunde):
                        datasets[2].data[i] += row.Days * workingHours * row.TargetRevenuePerHour;
                        //Mitarbeiterkosten (zu bezahlende Stunden * Kosten pro Stunde):
                        if(row.Contractor == 1)
                        {
                            datasets[3].data[i] += row.Days * workingHours * row.CostPerHour;
                        }
                        else
                        {
                            datasets[3].data[i] += row.DaysInclHolidays * workingHours * row.CostPerHour;
                        }
                    });
                }
                if(localViewMode == viewModes.Overview_Finance_Sites)
                {
                    const res = await fetcher(`${process.env.REACT_APP_API_URL}/Site?Start=${date_str}&End=${endDate_str}`);
                    const res_json = await res.json();
                    res_json.forEach((row: db_Site, index: number) => 
                    {
                        let existing_row = datasets.filter(r => r.label == row.Name);
                        if(existing_row.length > 0)
                        {
                            existing_row[0].data[i] = Math.round(row.Budget * finance_ratio * (row.days_ratio != null ? row.days_ratio : 1));
                        }
                        else
                        {
                            let new_row = {
                                backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`, //Object.values(CHART_COLORS)[Math.floor(random(0, Object.keys(CHART_COLORS).length))],
                                label: row.Name,
                                id: row.id_Site.toString(),
                                data: new Array(n_Months).fill(0),
                            };

                            new_row.data[i] = Math.round(row.Budget * finance_ratio * (row.days_ratio != null ? row.days_ratio : 1));
                            datasets.push(new_row);
                        }
                    });
                }
                if(localViewMode == viewModes.Overview_Availability_People)
                { 
                    const res = await fetcher(`${process.env.REACT_APP_API_URL}/Availability?Start=${date_str}T00:00:00Z&End=${endDate_str}T00:00:00Z`);
                    const res_json = await res.json();
                    res_json.forEach((row: db_Availability, index: number) => 
                    {
                        let existing_row = datasets.filter(r => r.label == row.Name);
                        if(existing_row.length > 0)
                        {
                            existing_row[0].data[i] = row.Days;
                        }
                        else
                        {
                            let new_row = {
                                backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`, //Object.values(CHART_COLORS)[Math.floor(random(0, Object.keys(CHART_COLORS).length))],
                                label: row.Name,
                                data: new Array(n_Months).fill(0),
                            };
                            new_row.data[i] = row.Days;
                            datasets.push(new_row);
                        }
                    });
                }
                if(localViewMode == viewModes.Overview_Load_People)
                {
                    const res = await fetcher(`${process.env.REACT_APP_API_URL}/Load?Date=${date_str}`);
                    const res_json = await res.json();
                    res_json.forEach((row: db_Month, index: number) => 
                    {
                        let existing_row = datasets.filter(r => r.label == row.Name);
                        if(existing_row.length > 0)
                        {
                            existing_row[0].data[i] = Math.round(row.DaysThisMonth * 10) / 10;
                        }
                        else
                        {
                            let new_row = {
                                backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`, //Object.values(CHART_COLORS)[Math.floor(random(0, Object.keys(CHART_COLORS).length))],
                                label: row.Name,
                                data: new Array(n_Months).fill(0),
                            };
                            new_row.data[i] = Math.round(row.DaysThisMonth * 10) / 10;
                            datasets.push(new_row);
                        }
                    });
                }
                if(localViewMode == viewModes.Overview_Load_Availability)
                {
                    if(datasets.length == 0)
                    {
                        let load_row = {
                            backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`, //Object.values(CHART_COLORS)[Math.floor(random(0, Object.keys(CHART_COLORS).length))],
                            label: 'load',
                            data: new Array(n_Months).fill(0),
                            stack: 'Stack 0',
                        };
                        let availability_row = {
                            backgroundColor: `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)})`, //Object.values(CHART_COLORS)[Math.floor(random(0, Object.keys(CHART_COLORS).length))],
                            label: 'availability',
                            data: new Array(n_Months).fill(0),
                            stack: 'Stack 1',
                        }; 
                        datasets.push(load_row);
                        datasets.push(availability_row);
                    }

                    const res = await fetcher(`${process.env.REACT_APP_API_URL}/Load?Date=${date_str}`);
                    const res_json = await res.json();
                    res_json.forEach((row: db_Month, index: number) => 
                    {
                        datasets[0].data[i] += row.DaysThisMonth;
                    });
                    let endDate = new Date(date.valueOf());
                    endDate.setMonth(endDate.getMonth() + 1);
                    const res2 = await fetcher(`${process.env.REACT_APP_API_URL}/Availability?Start=${date_str}T00:00:00Z&End=${endDate_str}T00:00:00Z&SubtractPlanned=1`);
                    const res2_json = await res2.json();
                    res2_json.forEach((row: db_Availability, index: number) => 
                    {
                        datasets[1].data[i] += row.Days;
                    });
                }
                //go to next month for next loop
                date.setMonth(date.getMonth() + 1);
            }
            if(localViewMode == viewModes.Overview_Finance_All || localViewMode == viewModes.Overview_Finance_Sites)
            {
                //Berechnung Gesamtbudget (nächsten 1000 Jahren)
                const startDateCopy = moment(startDate.valueOf());
                const startDate_str = startDateCopy.startOf('month').format('YYYY-MM-DD');
                const endDate_str = startDateCopy.add(1000, 'year').format('YYYY-MM-DD');
                const res3 = await fetcher(`${process.env.REACT_APP_API_URL}/Site?Start=${startDate_str}&End=${endDate_str}`);
                const res3_json = await res3.json();
                let totalBudget = 0;
                let totalBudgetExterior = 0;
                let totalBudgetInterior = 0;
                res3_json.forEach((row: db_Site, index: number) =>
                {
                    const site_budget = Math.round(row.Budget * finance_ratio * (row.days_ratio != null ? row.days_ratio : 1));
                    totalBudget += site_budget;
                    if(row.ExteriorWork === 1)
                    {
                        totalBudgetExterior += site_budget;
                    }
                    else
                    {
                        totalBudgetInterior += site_budget;
                    }
                });
                setTotalBudget(totalBudget);
                setTotalBudgetExterior(totalBudgetExterior);
                setTotalBudgetInterior(totalBudgetInterior);
            }
            setChartData({
                labels: labels.map(val => [val]),
                datasets: datasets
            });
            setLoading(false);
        };
        const fetchThreshold = async (localViewMode: viewModes) =>
        {
            let res;
            if(localViewMode == viewModes.Overview_Finance_Sites || localViewMode == viewModes.Overview_Finance_All)
            {
                res = await fetcher(`${process.env.REACT_APP_API_URL}/Globals/Monatsziel_CHF`);
            }
            else if(localViewMode != viewModes.Overview_Availability_People)
            {
                res = await fetcher(`${process.env.REACT_APP_API_URL}/Globals/Monatsziel_Tage`);
            }
            else
            {
                return;
            }
            const result_json = await res?.json();
            const newThreshold: number = parseFloat(result_json.Value);
            setThreshold(newThreshold);
        }
        let localViewMode = viewMode; 
        if(window.location.pathname.endsWith('overview'))
        {
            setViewMode(viewModes.Overview_Load_Availability);
            localViewMode = viewModes.Overview_Load_Availability;
        }
        else if(window.location.pathname.endsWith('load'))
        {
            setViewMode(viewModes.Overview_Load_People);
            localViewMode = viewModes.Overview_Load_People;
        }
        else if(window.location.pathname.endsWith('finance_sites'))
        {
            setViewMode(viewModes.Overview_Finance_Sites);
            localViewMode = viewModes.Overview_Finance_Sites;
        }
        else if(window.location.pathname.endsWith('finance_all') || window.location.pathname.endsWith('month'))
        {
            setViewMode(viewModes.Overview_Finance_All);
            localViewMode = viewModes.Overview_Finance_All;
        }
        else if(window.location.pathname.endsWith('availability'))
        {
            setViewMode(viewModes.Overview_Availability_People);
            localViewMode = viewModes.Overview_Availability_People;
        }
        else
        {
            console.log("Mode not supported");
        }
        fetchData(localViewMode);
        fetchThreshold(localViewMode);
    }, [pathChanged, startDate]);

    function onBtnClick()
    {
        setPathChanged(!pathChanged);
    }

    return (
        <div>
            <div className='flex item-center justify-between my-5'>
                <h1 className='text-2xl font-bold'>Monatsübersicht</h1>
                <div className={(loading ? 'opacity-100' : 'opacity-0') + " mr-5 transition-all duration-500   inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"}
                    role="status">
                    <span
                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                    >
                </div>
            </div>
            <dl className='shadow-xl p-5'>
                <div className='flex justify-between my-5'>
                    <div className='flex item-center'>
                        <div onClick={(e) => setStartDate(moment(startDate.valueOf()).subtract(1, 'month'))} className={`mt-7 m-1 h-8 w-8 rounded-full border-2 border-transparent hover:border-white cursor-pointer`}>
                            <ArrowSmLeftIcon />
                        </div>
                        <CustomDatePicker id='start-date' type='date' label='Startmonat' value={getStartDateDB(startDate)} onChange={(e) => setStartDate(moment(e.target.value).startOf('month'))} />
                        <div onClick={(e) => setStartDate(moment(startDate.valueOf()).add(1, 'month'))} className={`mt-7 m-1 h-8 w-8 rounded-full border-2 border-transparent hover:border-white cursor-pointer`}>
                            <ArrowSmRightIcon />
                        </div>
                    </div>
                    <nav className='flex flex-wrap items-center space-x-1'>
                        <CoolNavLink className='w-40' onClick={onBtnClick} align='center' to='/month/finance_all'>Finanzübersicht</CoolNavLink>
                        <CoolNavLink className='w-40' onClick={onBtnClick} align='center' to='/month/finance_sites'>Auftragsvolumen</CoolNavLink>
                        <CoolNavLink className='w-min whitespace-nowrap' onClick={onBtnClick} align='center' to='/month/overview'>Auslastung - Verfügbarkeit</CoolNavLink>
                        <CoolNavLink className='w-40' onClick={onBtnClick} align='center' to='/month/load'>Auslastung</CoolNavLink>
                        <CoolNavLink className='w-40' onClick={onBtnClick} align='center' to='/month/availability'>Verfügbarkeit</CoolNavLink>
                    </nav>
                </div>
                {viewMode == viewModes.Overview_Finance_All || viewMode == viewModes.Overview_Finance_Sites ?
                    <div className='flex justify-between h-0 opacity-[0.9]'>
                        <div />
                        <div className='relative top-5 right-5'>
                            <div className="max-w-sm rounded-xl overflow-hidden shadow-lg bg-gray-50 px-4 py-2">
                                <div className='font-bold'>Auftragsvolumen ab Startmonat</div>
                                <div className='flex'>
                                    <div>
                                        <div children={'gesamt:'}/>
                                        <div children={'Malerarbeit innen:'}/>
                                        <div children={'Malerarbeit aussen:'} />
                                    </div>
                                    <div className='ml-2'>
                                        <div>{totalBudget} CHF</div>
                                        <div>{totalBudgetInterior} CHF</div>
                                        <div>{totalBudgetExterior} CHF</div>
                                    </div>
                                </div>
                                {/* <p>Umsatz total:<br> + {totalBudget}</p>
                                <div children={'Umsatz Innenarbeiten: '+  totalBudgetInterior} />
                                <div children={'Umsatz Aussenarbeiten: '+  totalBudgetExterior} /> */}
                            </div>
                        </div>
                    </div> : null
                }
                <Bar className='max-h-[81%]'
                    options = {{
                        plugins: {
                            title: {
                                display: false,
                                text: "Aktive Tage pro Monat und Projekt"
                            },
                            legend: {
                                display: true,
                                position: "bottom"
                            },
                            annotation: {
                                annotations: {
                                    ...viewMode == viewModes.Overview_Availability_People || threshold == 0 ? null : {
                                        line1: {
                                            type: 'line',
                                            yMin: threshold,
                                            yMax: threshold,
                                            borderColor: 'rgb(99, 200, 132)',
                                            borderWidth: 5,
                                        }
                                    }
                                }
                            }
                                
                        },
                        scales: {
                            x: {
                                stacked: true,
                                title: {
                                    display: true,
                                    text: 'Monat'
                                }
                            },
                            y: {
                                stacked: true,
                                title: {
                                    display: true,
                                    text: ( viewMode == viewModes.Overview_Finance_Sites ? 'CHF' : 'Tage' )
                                }
                            }
                        },
                    }}
                    data={chartData}
                />
            </dl>
       </div>
    );
}

export default SiteOverview;