import { useEffect, useState } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { db_Person, db_Holiday, Absence, NewAbsence } from '../models';
import { SaveIcon } from '@heroicons/react/outline';
import { CustomButton, BackButton, EditButton, TrashButton, AddButton, SaveButton } from '../components/CustomButton';
import moment from 'moment';
import CustomInput from '../components/CustomInput';
import { fetcher } from '../request';
import CustomDatePicker from '../components/CustomDatePicker';
import { getStartDate, getEndDate, getStartDateDB, getEndDateDB, getShortName, eventToState } from '../helpers';
import { useSelector } from 'react-redux';
import { addAbsence, loadAbsences, selectAbsencesWeekdayIndexByPersonId, updateAbsence } from '../redux/absences/absencesSlice';
import store from '../redux/store';
import CustomCheckbox from '../components/CustomCheckbox';

function PersonDetail() {
    let { personId } = useParams();
    if (typeof personId === 'undefined') {
        personId = '';
    }
    let [person, setPerson] = useState<db_Person|null>(null);
    let [vacations, setVacations] = useState<db_Holiday[]>([]);
    let [creatingVacation, setCreatingVacation] = useState(false);
    let [newStartDate, setNewStartDate] = useState('');
    let [newEndDate, setNewEndDate] = useState('');
    let [newIntervalDays, setNewIntervalDays] = useState('');
    let [newIntervalEndTime, setNewIntervalEndTime] = useState(getStartDateDB(moment().add(1, 'year')));

    const absences = useSelector(selectAbsencesWeekdayIndexByPersonId(personId));
    
    let [editAbsences, setEditingAbsences] = useState(false);

    const absencesToValues = (absences: Record<string, Absence>) => {
        if (absences === undefined) {
            return {};
        }
        return Object.keys(absences).reduce((acc, key) => ({ ...acc, [key]: !!absences[key].Available }), {});
    }

    const [absenceValues, setAbsenceValues] = useState<Record<string, boolean>>(() => absencesToValues(absences));

    const changeAbsence = (day: string, available: boolean) => {
        setAbsenceValues({
            ...absenceValues,
            [day]: available
        });
    }

    const saveAbsences = () => {
        if (typeof personId === 'undefined' || personId === '') {
            return;
        }
        const personIdInt = parseInt(personId);
        absenceValues && Object.keys(absenceValues).forEach(day => {
            if (typeof absences === 'undefined' || typeof absences[day] === 'undefined' || absenceValues[day] !== !!absences[day].Available) {
                const currentDate = moment().utc().startOf('day');
                const newAbsence: NewAbsence = {
                    id_Person: personIdInt,
                    DayOfWeek: day,
                    Available: absenceValues[day] ? 1 : 0,
                    StartTime: currentDate.format('YYYY-MM-DD HH:mm:ss'),
                    Active: 1
                };
                if (typeof absences !== 'undefined' && typeof absences[day] !== 'undefined' && absences[day].StartTime === currentDate.toISOString()) {
                    const id = absences[day].id_Absences;
                    store.dispatch(updateAbsence({ id_Absences: id, ...newAbsence }));
                } else {
                    store.dispatch(addAbsence(newAbsence));
                }
            }
        });
    }

    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        store.dispatch(loadAbsences);
    }, []);

    useEffect(() => {
        setAbsenceValues(absencesToValues(absences));
    }, [absences]);

    useEffect(() => {
        const req = fetcher(process.env.REACT_APP_API_URL + '/Person/' + personId);
        req.then(res => res.json()).then(data => setPerson(data));

        const req2 = fetcher(process.env.REACT_APP_API_URL + '/Holidays');
        req2.then<db_Holiday[]>(res => res.json()).then(data => data.filter(e => (e.id_Person + '') === personId)).then(data => setVacations(data));
    }, [personId, creatingVacation]);

    const createVacation = () => {
        const req = fetcher(process.env.REACT_APP_API_URL + '/Holidays/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id_Person: personId,
                StartTime: getStartDateDB(newStartDate),
                EndTime: getEndDateDB(newEndDate, 1),
                RecurringIntervalDays: +newIntervalDays,
                RecurringEndTime: getEndDateDB(newIntervalEndTime, 1),
                Active: 1
            })
        }).then(res => {
            if(res.status === 200) {
                setCreatingVacation(false);
                // setVacations([...vacations, res.json()]);
            }
        });
        
    }

    const deleteVacation = (id: number) => {
        if(window.confirm('Ferien wirklich löschen?')) {
            const req = fetcher(process.env.REACT_APP_API_URL + '/Holidays/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Active: 0
                })
            }).then(res =>
            {
                if (res.status === 200)
                {
                    setVacations(vacations.filter(v => v.id_Holidays !== id));
                }
            });
        }
    };

    const deletePerson = (e: any) => {
        e.preventDefault();
        if(window.confirm('Mitarbeiter wirklich löschen?')) {
            if(person !== null)
            {
                const req = fetcher(process.env.REACT_APP_API_URL + '/Site/' + person?.id_Person, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Active: 0
                    })
                }).then(res => 
                {
                    if (res.status === 200) 
                    {
                        navigate('..')
                    }
                });
            }
        }
    }

    const updateIntervalDays = (e: any, vacation: db_Holiday) => 
    {
        let newIntervalDays = +e.target.value;
        const req = fetcher(process.env.REACT_APP_API_URL + '/Holidays/' + vacation.id_Holidays, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                RecurringIntervalDays: newIntervalDays
            })
        }).then(res =>
        {
            if (res.status === 200)
            {
                let vacationsCopy = [...vacations];
                vacationsCopy.filter(v => v.id_Holidays === vacation.id_Holidays)[0].RecurringIntervalDays = newIntervalDays.toString();
                setVacations(vacationsCopy)
            }
        });
    }

    return (
        <div>
            <BackButton state={ state } navigate={ navigate } />
            <div className='flex item-center justify-between my-5'>
                <h1 className='text-2xl font-bold'>Mitarbeiter</h1>
                <div className='flex justify-between space-x-2'>
                    <EditButton onClick={(e) => { e.stopPropagation(); navigate('./edit')}} />
                    <TrashButton onClick={(e) => deletePerson(e) } />
                </div>
            </div>
            <dl className='shadow-xl'>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{person?.Name}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Kurzbezeichnung</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getShortName(person?.Name)}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Startdatum</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getStartDate(person?.StartTime)}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Enddatum</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getEndDate(person?.EndTime)}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Zielumsatz pro Stunde</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{person?.TargetRevenuePerHour}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Kosten pro Stunde</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{person?.CostPerHour}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Arbeitet im Stundenlohn</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{person?.Contractor ? '✅' : '🛑'}</dd>
                </div>
            </dl>
            <div className='flex item-center justify-between my-5'>
                <h2 className='text-xl font-bold'>Arbeitstage</h2>
                <div className='flex justify-between space-x-2'>
                    {!editAbsences ? <EditButton onClick={(e) => { e.stopPropagation(); setEditingAbsences(true)}} /> : null}
                    {editAbsences ? <SaveButton  onClick={(e) => { e.stopPropagation(); setEditingAbsences(false); saveAbsences(); }} /> : null}
                </div>
            </div>
            <dl className='shadow-xl'>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6 border-y border-gray-200">
                    <dt className="text-sm font-medium text-gray-500"></dt>
                    <dt className="text-sm font-medium text-gray-500">Montag</dt>
                    <dt className="text-sm font-medium text-gray-500">Dienstag</dt>
                    <dt className="text-sm font-medium text-gray-500">Mittwoch</dt>
                    <dt className="text-sm font-medium text-gray-500">Donnerstag</dt>
                    <dt className="text-sm font-medium text-gray-500">Freitag</dt>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Morgen</dt>
                    {['Monday_Morning', 'Tuesday_Morning', 'Wednesday_Morning', 'Thursday_Morning', 'Friday_Morning'].map(day => {
                        const isAvailable = typeof absenceValues !== 'undefined' && (typeof absenceValues[day] === 'undefined' || absenceValues[day]);
                        if (editAbsences) {
                            return (
                                <dd key={day} className="mt-3 text-sm text-gray-900">
                                    <CustomCheckbox checked={isAvailable} onChange={(e) => changeAbsence(day, e.target.checked)} />
                                </dd>
                            )
                        }
                        return (
                            <dd key={day} className="text-sm text-gray-900">{isAvailable ? '✅' : '🛑'}</dd>
                        )
                    })}
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Nachmittag</dt>
                    {['Monday_Afternoon', 'Tuesday_Afternoon', 'Wednesday_Afternoon', 'Thursday_Afternoon', 'Friday_Afternoon'].map(day => {
                        const isAvailable = typeof absenceValues !== 'undefined' && (typeof absenceValues[day] === 'undefined' || absenceValues[day]);
                        if (editAbsences) {
                            return (
                                <dd key={day} className="mt-1 text-sm text-gray-900">
                                    <CustomCheckbox checked={isAvailable} onChange={(e) => changeAbsence(day, e.target.checked)} />
                                </dd>
                            )
                        }
                        return (
                            <dd key={day} className="text-sm text-gray-900">{isAvailable ? '✅' : '🛑'}</dd>
                        )
                    })}
                </div>
            </dl>
            <div className='flex item-center justify-between my-5'>
                <h2 className='text-xl font-bold'>Ferien</h2>
                <AddButton onClick={() => setCreatingVacation(true)} />
            </div>
            <dl className='shadow-xl'>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-y border-gray-200">
                    <dt className="text-sm font-medium text-gray-500">Start</dt>
                    <dt className="text-sm font-medium text-gray-500">Ende</dt>
                    {/* <dt className="text-sm font-medium text-gray-500">🔄 Intervall Tage</dt>
                    <dt className="text-sm font-medium text-gray-500">🔄 Enddatum</dt> */}
                    <dt className="text-sm font-medium text-gray-500"></dt>
                    <dt></dt>
                </div>
                {vacations.map((vacation, index) => {
                    return (
                        <div key={vacation.id_Holidays} className={(index % 2 === 0 ? 'bg-white' : 'bg-gray-50') + ' px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'}>
                            <CustomDatePicker className='flex' value={vacation.StartTime} url={`/Holidays/${vacation.id_Holidays}/StartTime`} />
                            <CustomDatePicker className='flex' value={vacation.EndTime} url={`/Holidays/${vacation.id_Holidays}/EndTime`} endDate={true} />
                            {/* <CustomInput className='flex flex-col' type='number' value={vacation.RecurringIntervalDays} onChange={(e) => updateIntervalDays(e, vacation)} />
                            <CustomDatePicker className='flex' value={vacation.RecurringEndTime} url={`/Holidays/${vacation.id_Holidays}/RecurringEndTime`} endDate={true} /> */}
                            <dd className="mt-3 text-sm text-gray-900">
                                <TrashButton className='min-w-40' onClick={() => deleteVacation(vacation.id_Holidays)} />
                            </dd>
                        </div>
                    )
                })}
                {creatingVacation ? (
                        <div className={(vacations.length % 2 === 0 ? 'bg-white' : 'bg-gray-50') + ' px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'}>
                            <CustomDatePicker className='flex' onChange={e => { setNewStartDate(getStartDateDB(e.target.value)) }} />
                            <CustomDatePicker className='flex' onChange={e => { setNewEndDate(getEndDateDB(e.target.value, 0)) }} />
                            {/* <CustomInput className='flex flex-col' type='number' value={newIntervalDays.toString()} onChange={ eventToState(setNewIntervalDays) } />
                            <CustomDatePicker className='flex' value={newIntervalEndTime} onChange={ eventToState(setNewIntervalEndTime) } endDate={true} /> */}
                            <dd className="mt-3 text-sm text-gray-900">
                                <CustomButton className='min-w-30' Icon={SaveIcon} onClick={createVacation}>Speichern</CustomButton>
                            </dd> 
                        </div>
                    ) : null}
            </dl>
        </div>
    );
}

export default PersonDetail;